$fonte-normal: "GandhiSansRegular";
$fonte-bold: "GandhiSansBold";
$cor-do-texto: #d9d9d9;
$azul-escuro: #041833;
$azul-claro: #154580;
$azul-rodape: #04244f;
$rosa: #c98cf1;
$roxo: #7b78e5;
$cinza-claro: #9d9d9d;
$tag-color: rgba(148, 163, 184, 0.3);
