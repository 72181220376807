@import "./variables";

.tags {
  width: 65vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $cor-do-texto;
  font-family: $fonte-normal;
  font-size: 16px;

  p {
    font-size: 1.25rem;
  }

  &__lista {
    list-style: none;
    width: 380px;
    padding: 0;
    display: flex;
    justify-content: space-between;

    li {
      background-color: $tag-color;
      padding: 6px 10px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}
